@keyframes float {
    0%, 100% {
      transform: translateY(0) rotate(45deg);
    }
    50% {
      transform: translateY(-20px) rotate(45deg);
    }
  }
  
  @keyframes pulse-glow {
    0%, 100% {
      opacity: 0.1;
      transform: scale(1);
    }
    50% {
      opacity: 0.2;
      transform: scale(1.1);
    }
  }